import React from "react";
import { Helmet } from "react-helmet";

const SEO = () => {

  return (
    <Helmet>
    </Helmet>
  );
};

export default SEO;