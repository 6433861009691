// extracted by mini-css-extract-plugin
export var logoContainer = "header-module--logo-container--KP1gq";
export var slogan = "header-module--slogan--rfL3k";
export var container = "header-module--container--YhtQ8";
export var menuIcon = "header-module--menu-icon--wUpif";
export var mobileMenu = "header-module--mobile-menu--cQL7p";
export var slideUp = "header-module--slideUp--SiZ6W";
export var slide = "header-module--slide--6icCA";
export var image = "header-module--image--IxEwI";
export var nav = "header-module--nav--utx5n";
export var socialMedia = "header-module--social-media--L+xjL";
export var navLink = "header-module--nav-link--5YtJ1";
export var innerCont = "header-module--inner-cont--jFNqZ";
export var navList = "header-module--nav-list--DIXmF";
export var navItem = "header-module--nav-item--gBpTC";